import React, {useState, useEffect} from "react";
import { Formik } from 'formik';
import styled from "styled-components";
import { motion } from "framer-motion";
import useInView from "use-in-view";
import tw from "twin.macro";
import * as Yup from 'yup';
import axios from 'axios';
import { css } from "styled-components/macro";

import states from '../../helpers/states';
import * as firebase from "firebase";

const Select = tw.select`shadow appearance-none my-2 border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline`;
const Input = tw.input`shadow grid-cols-2 appearance-none my-2 border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline`;
const Button = tw.button`w-full bg-primary-500 text-gray-100 font-bold mr-2 my-4 sm:my-2 rounded-full py-4 flex items-center justify-center sm:w-40 sm:leading-none focus:outline-none hover:bg-primary-900 transition duration-300`;
const Error = tw.p`text-purple-500`;
const Confirmation = tw.p`text-purple-700`;

const SignupSchema = Yup.object().shape({
    year: Yup.string()
        .required('Required'),
    make: Yup.string()
        .required('Required'),
    model: Yup.string()
        .required('Required'),
    zipcode: Yup.string()
        .matches(/^[0-9]{5}(?:-[0-9]{4})?$/, {message: 'Invalid zip code'})
        .required('Required'),
    mileage: Yup.string()
        .matches(/^[0-9][A-Za-z0-9 -]*$/, {message: 'Invalid mileage'})
        .required('Required'),
    state: Yup.string()
        .required('Required'),
});

const PersonalInfoSchema = Yup.object().shape({
    firstname: Yup.string()
        .required('Required'),
    lastname: Yup.string()
        .required('Required'),
    email: Yup.string()
        .email('Email address is incorrect')
        .required('Required'),
    income: Yup.string()
        .required('Required'),
    phone: Yup.number()
        .min(10)
        .required('Required'),
    owned: Yup.string()
        .required('Required'),
});

export default () => {

    const [slide, setSlide] = useState(1);

    const [vehicleData, setVehicleData] = useState(null);

    const submit = (personal, vehicle) => {
        const data = {
            api_key: '5f4eb32f-d82c-46ee-8f38-34b7c0a87b0a',
            ApplicationApplicant: {
                first_name: personal.firstname,
                last_name: personal.lastname,
                city: vehicle.state,
                state: vehicle.state,
                zip_code: vehicle.zipcode,
                phone: personal.phone,
                sms: 0,
                email: personal.email,
                monthly_income: personal.income,
            },
            ApplicationVehicle: {
                vehicle_make: vehicle.make,
                vehicle_model: vehicle.model,
                vehicle_mileage: vehicle.mileage,
                vehicle_year: vehicle.year,
                vehicle_owned: personal.owned,
            }
        };

        axios.post('https://services.titleloandocs.com/api/application_add ', data)
            .then(() => {
                setSlide(3);
                firebase.analytics().logEvent('Application Submitted');
            })
            .catch(error => {
                console.log(error);
                firebase.analytics().logEvent('Application Failed');
                setSlide(3)
            })
    };

    const [ref, inView] = useInView(30);
    const x = { target: "0%" };

    x.initial = "-150%";

    if (slide === 1) {
        return (
                <Formik
                    id="vehicleInfoForm"
                    initialValues={{ year: '', make: '', model: '', zipcode: '', mileage: '', state: '' }}
                    validationSchema={SignupSchema}
                    onSubmit={(values, { setSubmitting }) => {
                        setVehicleData(values);
                        setSlide(2);
                    }}
                >
                    {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          isSubmitting,
                          /* and other goodies */
                      }) => (
                        <form onSubmit={handleSubmit} id="form1">
                            <div tw="grid sm:grid-cols-2 grid-cols-1 gap-4">
                                <div>
                                    <Select
                                        placeholder="Vehicle Year"
                                        name="year"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.year}
                                    >
                                        <option value="">Vehicle Year</option>
                                        <option value="2021">2021</option>
                                        <option value="2020">2020</option>
                                        <option value="2019">2019</option>
                                        <option value="2018">2018</option>
                                        <option value="2017">2017</option>
                                        <option value="2016">2016</option>
                                        <option value="2015">2015</option>
                                        <option value="2014">2014</option>
                                        <option value="2013">2013</option>
                                        <option value="2012">2012</option>
                                        <option value="2011">2011</option>
                                        <option value="2010">2010</option>
                                        <option value="2009">2009</option>
                                        <option value="2008">2008</option>
                                        <option value="2007">2007</option>
                                        <option value="2006">2006</option>
                                        <option value="2005">2005</option>
                                        <option value="2004">2004</option>
                                        <option value="2003">2003</option>
                                        <option value="2002">2002</option>
                                        <option value="2001">2001</option>
                                        <option value="2000">2000</option>
                                        <option value="1999">1999</option>
                                        <option value="1998">1998</option>
                                        <option value="1997">1997</option>
                                        <option value="1996">1996</option>
                                        <option value="1995">1995</option>
                                        <option value="1994">1994</option>
                                        <option value="1993">1993</option>
                                        <option value="1992">1992</option>
                                        <option value="1991">1991</option>
                                        <option value="1990">1990</option>
                                        <option value="1989">1989</option>
                                        <option value="1988">1988</option>
                                        <option value="1987">1987</option>
                                        <option value="1986">1986</option>
                                        <option value="1985">1985</option>
                                        <option value="1984">1984</option>
                                        <option value="1983">1983</option>
                                        <option value="1982">1982</option>
                                        <option value="1981">1981</option>
                                        <option value="1980">1980</option>
                                        <option value="classic">Classic</option>
                                    </Select>
                                    <Error>{errors.year && touched.year && errors.year}</Error>
                                </div>
                                <div>
                                    <Input
                                        type="text"
                                        placeholder="Vehicle Make"
                                        name="make"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.make}
                                    />
                                    <Error>{errors.make && touched.make && errors.make}</Error>
                                </div>
                                <div>
                                        <Input
                                            type="text"
                                            placeholder="Vehicle Model"
                                            name="model"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.model}
                                        />
                                    <Error>{errors.model && touched.model && errors.model}</Error>
                                </div>
                                <div>
                                    <Input
                                        type="text"
                                        placeholder="Mileage"
                                        name="mileage"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.mileage}
                                    />
                                    <Error>{errors.mileage && touched.mileage && errors.mileage}</Error>
                                </div>
                                <div>
                                    <Select
                                        name="state"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.state}
                                    >
                                        <option value="" disabled>Select State</option>
                                        {Object.keys(states).map((item, i) => <option value={item} key={i}>{states[item]}</option>)}

                                    </Select>
                                    <Error>{errors.state && touched.state && errors.state}</Error>
                                </div>
                                <div>
                                    <Input
                                        type="text"
                                        name="zipcode"
                                        placeholder="Zip Code"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.zipcode}
                                    />
                                    <Error>{errors.zipcode && touched.zipcode && errors.zipcode}</Error>
                                </div>
                                <div>
                                    <Button type="submit" disabled={isSubmitting}>
                                        Get Started
                                    </Button>
                                </div>
                            </div>


                        </form>
                    )}
                </Formik>
            // </motion.section>
        )
    }

    if (slide === 2) {
        return (
            <motion.section
                initial={{x: x.initial}}
                animate={{
                    x: inView && x.target,
                    transitionEnd: {
                        x: inView && 0
                    }
                }}
                transition={{type: "spring", damping: 100}}
                ref={ref}
            >
                <Formik
                    id="personalInfoForm"
                    initialValues={{
                        firstname: '',
                        lastname: '',
                        email: '',
                        phone: '',
                        income: '',
                        owned: '',
                        terms: ''
                    }}
                    validationSchema={PersonalInfoSchema}
                    onSubmit={(values, {setSubmitting}) => {
                        submit(values, vehicleData);
                    }}
                >
                    {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          isSubmitting,
                          /* and other goodies */
                      }) => (
                        <form onSubmit={handleSubmit} id="form2">
                            <div tw="grid sm:grid-cols-2 grid-cols-1 gap-4">
                                <div>
                                    <Input
                                        type="text"
                                        placeholder="First Name"
                                        name="firstname"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.firstname}
                                    />
                                    <Error>{errors.firstname && touched.firstname && errors.firstname}</Error>
                                </div>
                                <div>
                                    <Input
                                        type="text"
                                        placeholder="Last Name"
                                        name="lastname"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.lastname}
                                    />
                                    <Error>{errors.lastname && touched.lastname && errors.lastname}</Error>
                                </div>
                                <div>
                                    <Input
                                        type="text"
                                        placeholder="Email Address"
                                        name="email"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.email}
                                    />
                                    <Error>{errors.email && touched.email && errors.email}</Error>
                                </div>
                                <div>
                                    <Input
                                        type="text"
                                        placeholder="Phone Number"
                                        name="phone"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.phone}
                                    />
                                    <Error>{errors.phone && touched.phone && errors.phone}</Error>
                                </div>
                                <div>
                                    <Input
                                        type="text"
                                        placeholder="Monthly Income"
                                        name="income"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.income}
                                    />
                                    <Error>{errors.income && touched.income && errors.income}</Error>
                                </div>
                                <div>
                                    <Select
                                        name="owned"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.owned}
                                    >
                                        <option value="" disabled>Do you own the vehicle?</option>
                                        <option value="1">Yes, it's paid off</option>
                                        <option value="0">No, I'm making payments</option>
                                        <option value="2">No, I'm looking to refinance</option>
                                    </Select>
                                    <Error>{errors.owned && touched.owned && errors.owned}</Error>
                                </div>
                                <div>
                                    <Button type="submit" disabled={isSubmitting}>
                                        Finish
                                    </Button>
                                </div>
                            </div>


                        </form>
                    )}
                </Formik>
            </motion.section>
        )
    }

    return (
        <div>
            <Confirmation>Your application has been submitted. One of our advisors will contact you shortly to confirm your information and get your money moving.</Confirmation>
            <div tw="grid sm:grid-cols-2 grid-cols-1 gap-4 justify-center">
                <Button type="submit" onClick={() => setSlide(1)}>
                    Start Over
                </Button>
            </div>
        </div>
    )
}
