import React from 'react';
import "tailwindcss/dist/base.css";
import "styles/globalStyles.css";
import './App.css';
import Home from './pages/index';

import * as firebase from "firebase/app";

// If you enabled Analytics in your project, add the Firebase SDK for Analytics
// import "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyCnpzf6wC_oLsiTeJf76YoGfjr_yMiXl4E",
  authDomain: "ultimate-title-loans.firebaseapp.com",
  databaseURL: "https://ultimate-title-loans.firebaseio.com",
  projectId: "ultimate-title-loans",
  storageBucket: "ultimate-title-loans.appspot.com",
  messagingSenderId: "421873430690",
  appId: "1:421873430690:web:15bfc1f86c8f1bc536a7fb",
  measurementId: "G-RK102P5GQ8"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();


function App() {
  return (
    <Home />
  );
}

export default App;
