import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/TwoColumnWithInput.js";
import Features from "components/features/ThreeColWithSideImage.js";
import MainFeature2 from "components/features/TwoColWithTwoHorizontalFeaturesAndButton.js";
import FeatureWithSteps from "components/features/TwoColWithSteps.js";
import Testimonial from "components/testimonials/TwoColumnWithImageAndRating.js";
import FAQ from "components/faqs/SingleCol.js";
import GetStarted from "components/cta/GetStarted";
import Footer from "components/footers/FiveColumnWithBackground.js";
import heroScreenshotImageSrc from "images/hero-screenshot-1.png";
import customersupport from "images/customer-support-illustration.svg"
import macHeroScreenshotImageSrc from "images/hero-screenshot-2.png";
import prototypeIllustrationImageSrc from "images/prototype-illustration.svg";
import { ReactComponent as BriefcaseIcon } from "feather-icons/dist/icons/briefcase.svg";
import { ReactComponent as MoneyIcon } from "feather-icons/dist/icons/dollar-sign.svg";

export default () => {
    const Subheading = tw.span`uppercase tracking-widest font-bold text-primary-500`;
    const HighlightedText = tw.span`text-primary-500`;

    return (
        <AnimationRevealPage>
            <Hero roundedHeaderButton={true} />
            <Features
                subheading={<Subheading>Get a title loan</Subheading>}
                heading={
                    <>
                        Keep your car. Get <HighlightedText>money</HighlightedText> today.
                    </>
                }
            />

            <a name="whyus"/>
            <FeatureWithSteps
                subheading={<Subheading>TITLE LOAN SPECIALISTS</Subheading>}
                heading={
                    <>
                        Why get a title loan <HighlightedText>with us?</HighlightedText>
                    </>
                }
                textOnLeft={false}
                imageSrc={customersupport}
                imageDecoratorBlob={true}
                decoratorBlobCss={tw`xl:w-40 xl:h-40 opacity-15 -translate-x-1/2 left-1/2`}
            />
            <MainFeature2
                subheading={<Subheading>VALUES</Subheading>}
                heading={
                    <>
                        Fast Title Loans, <HighlightedText>Get Cash Today.</HighlightedText>
                    </>
                }
                imageSrc={prototypeIllustrationImageSrc}
                showDecoratorBlob={false}
                features={[]}
            />

            <a name="testimonials" />

            <Testimonial
                subheading={<Subheading>Testimonials</Subheading>}
                description=""
                heading={
                    <>
                        Our Customers <HighlightedText>Love Us.</HighlightedText>
                    </>
                }
                testimonials={[
                    {
                        stars: 5,
                        profileImageSrc:
                            "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=3.25&w=512&h=512&q=80",
                        heading: "Really fast and friendly",
                        quote:
                            "My loan was arranged the same day, I sent in my supporting documents and had the money the following day.",
                        customerName: "Charlotte H",
                        customerTitle: "California"
                    },
                    {
                        stars: 5,
                        profileImageSrc:
                            "https://images.unsplash.com/photo-1531427186611-ecfd6d936c79?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.25&w=512&h=512&q=80",
                        heading: "They accepted my unemployment insurance",
                        quote:
                            "I needed some money after being furloughed, my unemployment insurance and title was enough to get money to me within 48 hours start to finish. Really fast and easy experience.",
                        customerName: "Adam C",
                        customerTitle: "Texas"
                    }
                ]}
            />
            <a name="faq"/>
            <FAQ
                subheading={<Subheading>FAQS</Subheading>}
                description="We have answers!"
                heading={
                    <>
                        You have <HighlightedText>Questions ?</HighlightedText>
                    </>
                }
                faqs={[
                    {
                        question: "What is a car title loan?",
                        answer:
                            "Car title loans are secured loans, which use your vehicle to back the loan. In the case of a car title loan, customers borrow against the value of their vehicle. This means that lenders will use your car’s value to determine your loan amount. For example, if your vehicle’s current market value is $6,000, then your loan amount can be up to $6,000. Borrowers can still keep their car for personal use, but will need to transfer the vehicle title to the lender in order to back their loan. Should a borrower default on the loan and is unable to repay the loan in full, vehicle ownership will be transferred to the lender to cover the debt. However, when the loan is paid off, the lender will transfer the title back to the borrower’s name."
                    },
                    {
                        question: "How does a car title loan work?",
                        answer:
                            "Car owners can use car title loans as an opportunity to borrow a loan using the value of their car. The car serves as a collateral asset for your loan since the loan is not based on your credit. Car title loans are a great option for people who struggle with poor credit because more people are able to qualify for a loan. If your car carries enough value, you’ll be able to get a loan up to that amount.\n" +
                            "\n" +
                            "When you take out a car title loan, you will have to transfer your car title under the lender’s name, but the title will be transferred back to you when the loan is paid off. If the borrower is unable to pay off the loan and defaults, the car will stay under the lender’s name until the loan is paid in full."
                    },
                    {
                        question: "How fast can I receive the loan?",
                        answer:
                            "Our customers have been able to receive their loan in as little as 24 hours. To expedite your process, we highly recommend our customers prepare the appropriate documents and paperwork in advance. This will cut down your processing time and help you receive your loan faster."
                    },
                    {
                        question: "How do you qualify for a car title loan?",
                        answer:
                            "With a car title loan, your eligibility for your loan is based entirely on your vehicle value. You can get an estimate for your vehicle value right away by filling out the form above or by calling 844-354-2920."
                    },
                    {
                        question: "Can I get a car title loan without the car title?",
                        answer:
                            "Since your car title will serve as a collateral asset for a car title loan, it would be necessary to have your car title in order to take out a loan. In order to qualify for a car title loan, the title must demonstrate proof that you are the owner of the vehicle in use. For this reason, the car title must be under the borrower’s name."
                    },
                    {
                        question: "Do I need good credit for a car title loan?",
                        answer:
                            "Although most lenders and banks will turn you down if your credit score is poor, you will not be disqualified for a car title loan with Ultimate Car Title Loans. Since the loan is based on your car’s value, you can still get a loan even if you have bad credit."
                    }
                ]}
            />
            <GetStarted/>
            <Footer />
        </AnimationRevealPage>
    );
}
